import TitleImage from './resources/Title.png';
import LocationMapImage from './resources/LocationMap.png';
import GalleryPhoto1 from './resources/Gallery_Photo_1.png';
import GalleryPhoto2 from './resources/Gallery_Photo_2.png';
import GalleryPhoto3 from './resources/Gallery_Photo_3.png';
import GalleryPhoto4 from './resources/Gallery_Photo_4.png';
import GalleryPhoto5 from './resources/Gallery_Photo_5.png';
import GalleryPhoto6 from './resources/Gallery_Photo_6.png';
import GalleryPhoto7 from './resources/Gallery_Photo_7.png';
import GalleryPhoto8 from './resources/Gallery_Photo_8.png';
import GalleryPhoto9 from './resources/Gallery_Photo_9.png';
import GalleryPhoto10 from './resources/Gallery_Photo_10.png';
import GalleryPhoto11 from './resources/Gallery_Photo_11.png';
import GalleryPhoto12 from './resources/Gallery_Photo_12.png';
import GalleryPhoto13 from './resources/Gallery_Photo_13.png';
import GalleryPhoto14 from './resources/Gallery_Photo_14.png';
import GalleryPhoto15 from './resources/Gallery_Photo_15.png';
import GalleryPhoto16 from './resources/Gallery_Photo_16.png';

const Configs: ConfigsType = {
  url: 'https://tiecbaohy.stackgoo.com',
  kakaoToken: '',
  kakaoImage: '',
  weddingDate: '18h00, ngày 20 tháng 05 năm 2023',
  weddingLocation: 'Sảnh Upper nhà hàng ÉN RESTAURANT, Tòa nhà Robot Tower, 308C Điện Biên Phủ, Phường 4, Quận 3, TP. HCM',
  groom: {
    name: 'Thành Huynh',
    accountNumber: 'ACB: 7968791368',
    fatherName: 'Đỗ Đình Huy',
    fatherAccountNumber: '',
    motherName: 'Nguyễn Thị Ký',
    motherAccountNumber: '',
  },
  bride: {
    name: 'Ngọc Nguyễn',
    accountNumber: 'MOMO: 0964049809',
    fatherName: '○○○',
    fatherAccountNumber: '',
    motherName: 'Nguyễn Thị Thiện',
    motherAccountNumber: '',
  },
  titleImage: TitleImage,
  locationMapImage: LocationMapImage,
  galleryImages: [
    GalleryPhoto1,
    GalleryPhoto2,
    GalleryPhoto3,
    GalleryPhoto4,
    GalleryPhoto5,
    GalleryPhoto6,
    GalleryPhoto7,
    GalleryPhoto8,
    GalleryPhoto9,
    GalleryPhoto10,
    GalleryPhoto11,
    GalleryPhoto12,
    GalleryPhoto13,
    GalleryPhoto14,
    GalleryPhoto15,
    GalleryPhoto16,
  ],
};

export type ConfigsType = {
  url: string;
  kakaoToken: string;
  kakaoImage: string;
  weddingDate: string;
  weddingLocation: string;
  groom: Person;
  bride: Person;
  titleImage: string;
  locationMapImage: string;
  galleryImages: string[];
};

type Person = {
  name: string;
  accountNumber: string;
  fatherName?: string;
  fatherAccountNumber?: string;
  motherName?: string;
  motherAccountNumber?: string;
};

export default Configs;
