import { useRef } from 'react';
import { styled } from '@stitches/react';
import { ConfigsType } from '../configs';

const isPortrait = window.matchMedia('(orientation: portrait)').matches;

const Section = styled('section', {
  background: '#EFEBE9',
  overflow: 'hidden',
  position: 'relative',
});

const Layout = styled('div', {
  width: '100%',
  padding: isPortrait ? '15% 5%' : '5% 10%',
});

const Title = styled('p', {
  color: '#795548',
  width: '100%',
  fontSize: isPortrait ? '2.5em' : '3.5em',
  margin: 0,
  fontWeight: '500',
  textAlign: 'center',
});

const SubTitle = styled('p', {
  color: '#795548',
  width: '100%',
  fontSize: isPortrait ? '1.2em' : '2em',
  margin: '24px 0',
  fontWeight: '300',
  lineHeight: 1.8,
  textAlign: 'center',
});

type LocationProps = {
  config: ConfigsType;
};

const Location = ({ config }: LocationProps) => {
  const ref = useRef<HTMLSelectElement>(null);

  return (
    <Section ref={ref}>
      <Layout>
        <Title>Hướng dẫn tìm nhà hàng ÉN RESTAURANT</Title>
        <SubTitle>
          Tầng trên cùng, sảnh Upper tòa nhà Robot Tower, 308C Điện Biên Phủ, Phường 4, Quận 3, TP. HCM
          <br />
          <br />
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.456832822764!2d106.6828778!3d10.7762806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f242e0f0ffd%3A0xc57f4a5451ba4e36!2s%C3%89n%20Restaurant%20%26%20Event%20Space!5e0!3m2!1svi!2s!4v1678799001375!5m2!1svi!2s" width="auto" height="450" loading="lazy" ></iframe>
        </SubTitle>
      </Layout>
    </Section>
  );
};

export default Location;
