import { useRef } from 'react';
import { styled } from '@stitches/react';
import useOnScreen from '../hooks/useOnScreen';
import { ConfigsType } from '../configs';

const isPortrait = window.matchMedia('(orientation: portrait)').matches;

const Layout = styled('div', {
  width: '100%',
  padding: isPortrait ? ' 15% 5%' : '5% 10%',
  textAlign: 'center',
});

const Title = styled('p', {
  color: '#795548',
  width: '100%',
  fontSize: isPortrait ? '2.5em' : '3.5em',
  margin: 0,
  fontWeight: '500',
  textAlign: 'center',
});

const SubTitle = styled('p', {
  color: '#795548',
  width: '100%',
  fontSize: isPortrait ? '1.2em' : '2em',
  margin: '24px 0',
  fontWeight: '300',
  lineHeight: 1.8,
  textAlign: 'center',
});

type GreetingProps = {
  config: ConfigsType;
};

const Greeting = ({ config }: GreetingProps) => {
  const ref = useRef<HTMLSelectElement>(null);
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, '-125px');

  return (
    <section
      ref={ref}
      style={{
        // height: '100vh',
        background: onScreen ? '#EFEBE9' : '#DADADA',
        overflow: 'hidden',
        position: 'relative',
        transition: 'background 1s ease-in',
      }}
    >
      <Layout>
        Trân trọng báo tin
        <Title>LỄ BÁO HỶ</Title>
        của chúng tôi
        <SubTitle>
          <Title>{config.groom.name}</Title>
          &
          <Title>{config.bride.name}</Title>
          <br />

          Lễ thành hôn được cử hành tại Tư Gia
          Vào lúc 10h00 ngày 07 tháng 05 năm 2023
          (Nhằm ngày 18 tháng 03 năm Quý Mão)
          <br />
          <br />
          Trân trọng kính mời Quý vị đến dự buổi tiệc rượu chung vui cùng gia đình chúng tôi tại:
          <br />
          Nhà Hàng Én Restaurant
          <br />
          Sảnh UPPER, tòa nhà Robot Tower, 308C Điện Biên Phủ, Phường 4, Quận 3, TP. HCM
          <br />
          Vào lúc 18 giờ 00 Thứ 7 ngày 20 tháng 05 năm 2023
          <br />
          <br />
          Sự hiện diện của Quý vị là niềm vui lớn nhất của gia đình chúng tôi.

          <br />
          <br />
          TRÂN TRỌNG KÍNH MỜI
          <br />
          <br />
          Đón khách: 18 giờ 00
          <br />
          Khai tiệc: 19 giờ 00
        </SubTitle>
      </Layout>
    </section>
  );
};

export default Greeting;
